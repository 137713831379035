import React from 'react'
import { Link } from 'react-router-dom'

import {Button, Grid, Icon, Image, RatingIcon } from 'semantic-ui-react'
import appleStore from '../../assets/images/apple-store.png'
import playStore from '../../assets/images/play-store.png'

 function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return window.open('https://play.google.com/store/apps/details?id=com.terrachow.terrachow')
        
  }

  if (/android/i.test(userAgent)) {
    return window.open('https://play.google.com/store/apps/details?id=com.terrachow.terrachow')
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return  window.open('https://apps.apple.com/ng/app/terrachow/id1587526296')
  }

  return window.open('https://apps.apple.com/ng/app/terrachow/id1587526296')
  ;
}
const Footer = () => { 
return (
  <>
    <div className='footer'>
        <div className='xx-footer'>
          <Grid stackable>
            <Grid.Column floated='left' width={5} className='x-left'  mobile={16} computer={8} tablet={8}  >
              <div className="x-left" style={{display: 'flex'}}>
                <div>
                  <a href="https://play.google.com/store/apps/details?id=com.terrachow.terrachow" target='_blank'>
                    <Image src={playStore} />
                  </a>

                </div>
                <div className='x-store'>
                  <a href="https://apps.apple.com/us/app/terrachow/id1587526296" target='_blank'>
                    <Image src ={appleStore} />
                  </a>

                </div>

              </div>
            </Grid.Column>
            {/* <Grid.Column floated='right' width={5}>
              <div className='xx-flex'>
                <div className='x-about'>
                <h4><a href="#">About us</a></h4>
                    <ul>
                        <li><a href="#">Restaurants near me</a></li>
                        <li><a href="#">Add your Restaurant</a></li>
                    </ul>
                </div>
                <div className="x-get">
                <h4><a href="#">Get help</a></h4>
                    <ul>
                        <li><a href="#">Read FAQ’s</a></li>
                        <li><a href="#">Restaurants near me</a></li>
                        <li><a href="#">Support</a></li>
                    </ul>
                </div>
              </div>
            </Grid.Column> */}
          </Grid>
        </div>
        <div className='x-policy'>
          <Grid divided='vertically' stackable  mobile={16} computer={8} tablet={8}  >
            <Grid.Row columns={3} className='x-terms'>
              <Grid.Column>
                <div className='x-cookies'>
                <ul class="">
                        <li><Link to="/tos">TERMS & CONDITIONS</Link></li>
                        <li><Link to="/privacy-policy">PRIVACY POLICY</Link></li>
                        <li><Link to="/about">ABOUT US</Link></li>
                        <li className='x-hidden-desktop'><a href="https://apps.apple.com/ng/app/terrachow/id1587526296">DOWNLOAD ON IOS</a></li>
                        <li className='x-hidden-desktop'><a href="https://play.google.com/store/apps/details?id=com.terrachow.terrachow">DOWNLOAD ON ANDROID</a></li>
                    </ul>
                </div>
              </Grid.Column>
              <Grid.Column>
                <div className='x-icons'>
                  <div>
                    <ul>
                        <li>
                          <a href="https://facebook.com/terrachow">
                            <Icon className='facebook f' />
                          </a>
                        </li>    
                        <li>
                          <a href="https://twitter.com/terra_chow">
                            <Icon className='twitter' />
                          </a>
                        </li>    
                        <li>
                          <a href="https://instagram.com/terrachow">
                            <Icon className='instagram' />
                          </a>
                        </li> 
                    </ul>

                  </div>
                  <div>
                        <p class="text-white">
                            Follow us on these social media platforms
                        </p>   

                  </div>
                </div>
              </Grid.Column>
              <Grid.Column  >
                <div className='x-office'>
                      <ul class="terrachow">
                        <li><a href="#">Office: +2349160276487</a></li>
                        <li><a href="#">info@terrachow.com</a></li>
                     </ul>
                </div>
              </Grid.Column>
          </Grid.Row>
          </Grid>
        </div>

        <div className='get-it x-hidden-desktop'>
        <div className='x-store'>
        <img src='https://is3-ssl.mzstatic.com/image/thumb/Purple113/v4/f8/80/70/f880703a-a547-4087-c68d-fe305ac9792c/AppIcon-1x_U007emarketing-0-5-0-85-220.png/350x350.png?'
        />
        
        <div>
          <p>Terrachow</p>
            <div style={{lineHeight: '0'}}>
            <Icon name="star" color='yellow' />
        <Icon name="star" color='yellow' />
        <Icon name="star" color='yellow' />
        <Icon name="star" color='yellow' />
        <Icon name="star " color='yellow' />
            </div>
        </div>
        </div>
        <div>
          <Button compact style={{background: "#EA2125"}}  onClick={getMobileOperatingSystem}>Get the App</Button>
        </div>
    </div>
    </div>
    </>
  )
}

export default Footer