import React from 'react'

import { Grid, GridRow, Icon, Image } from 'semantic-ui-react'
import human from '../../assets/images/human.png'
import foodImage from '../../assets/images/rice.png'
import appleStore from '../../assets/images/apple-store.png'
import playStore from '../../assets/images/play-store.png'


const RestaurantPartner = () => {
  return (

    <div className='x-container'>
        <div className='x-rest ui fluid containe'>
            <Grid style={{height: "100%"}} className='xxx-fle' stackable >
                <Grid.Row columns={2} className='x-flex'>
                    <Grid.Column   className='x-left' >
                        <div className='x-become'>
                            <p>Become a restaurant<br></br> partner</p>
                            <h3>Bringing the 
                                customers to you, 
                                where they belong
                            </h3>
                            <div className='x-terra'>
                            Terrachow platform gives you the flexibility, visibility and customer insights you need to connect with more customers. Our model incoporated in our services will help your business to get more revenue, thus attaining the growth needed for your business. Partner with us today.
                            </div>
                            <a href="#">Register your restaurant</a> <span className='arrow-left'><Icon className='arrow right'></Icon></span>
                        </div>
                    </Grid.Column>
                    <Grid.Column  mobile={16} computer={8} tablet={8}  verticalAlign="middle" textAlign="center">
                        <div className='image-padd'>
                            <Image src= {human} size='large' className='human'/>
                        </div>
                    </Grid.Column>
                </Grid.Row>

            </Grid>
        </div>
        <div className='x-deliver'>
            <Grid divided='vertically' stackable>
                <Grid.Row columns={2} className='x-mobile'>
                    <Grid.Column>
                        <div className='food-image'>
                            <Image className='food-img' src={foodImage} size='large'/>
                        </div>
                    </Grid.Column>
                    <Grid.Column>
                        <div className='x-virtual'>
                            <p>Your favorites, <br></br>
                            delivered</p>
                            <h3>
                            Easiest Way to <span className='pocket'>Pocket </span>  
                            all the <span className='rants'>Restaurants</span> 
                            </h3>
                            <div>
                            Order from your favorite physical and virtual restaurants & track on the go, with the all-new Terrachow App.
                            </div>
                            <div className='store-container'>
                                <a href="https://apps.apple.com/us/app/terrachow/id1587526296" target='_blank'>
                                    <Image src = {appleStore} />
                                </a>
                                <a href="https://play.google.com/store/apps/details?id=com.terrachow.terrachow" target='_blank'>
                                    <Image src={playStore} />
                                </a>
                            </div>
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>

        <div className="food-partners">
            <Grid divided='vertically' stackable  mobile={16} computer={8} tablet={8}  >
                <Grid.Row columns={3}>
                    <Grid.Column>
                        <div className='x-grip'>
                            <h3>
                                Some of our restaurant<br></br>
                                partners
                            </h3>
                            <ul>
                                <li><a href="#">Bolz Grillz</a></li>
                                <li><a href="#">Iya Yusuf restaurants</a></li>
                                <li><a href="#">Hyshoo hot and spices</a></li>
                                <li><a href="#">Bookies Nosh Up</a></li>
                                <li><a href="#">Red Caffino</a></li>
                                <li><a href="#">Village Kitchen</a></li>
                                <li><a href="#">Masa Spot</a></li>
                                <li><a href="#">Kokokings</a></li>
                                <li><a href="#">Icons Kitchen</a></li>
                                <li><a href="#">Diamond Healthies</a></li>
                                <li><a href="#">Abis Cravings</a></li>
                            </ul>
                        </div>
                    </Grid.Column>
                    <Grid.Column>
                        <div className='x-grills'>
                            <h3>Food near <br></br>me</h3>
                            <ul>
                                <li><a href="#">Swallow</a></li>
                                <li><a href="#">Grills</a></li>
                                <li><a href="#">Small chops</a></li>
                                <li><a href="#">Desserts</a></li>
                                <li><a href="#">Yoghurt and Parfait</a></li>
                                <li><a href="#">Grains</a></li>
                                <li><a href="#">Proteins</a></li>
                                <li><a href="#">Northern delicacies</a></li>
                                <li><a href="#">Local dishes</a></li>
                                <li><a href="#">Drinks</a></li>
                                <li><a href="#">Peppersoup</a></li>
                            </ul>

                        </div>
                    </Grid.Column>
                    <Grid.Column>
                        <div className='x-illorin'>
                            <h3>Top cities</h3>
                            <ul>
                                <li><a href="#">Ilorin</a></li>
                            </ul>
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
     </div>   
  )
}

export default RestaurantPartner