import React from "react";
import { Grid, Icon, Image,  } from 'semantic-ui-react'
import Nav from '../../components/navbar/nav'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "../../components/homepages/Footer";



const About = (props) => {
const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 5000, 
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    
};
    return (
        <div className="about-us">
   <Nav {...props} />
   <div className="x-container">
   <div className="contents">
      <p className="x-subtitle">  Our Mission</p>
      <h1>Access to good food should be easy,</h1>
      <h1>just like the air you breathe</h1>
      <p> Scroll for more <Icon name="arrow  down" style={{fontSize:".8em", marginLeft: '.5em'}}/></p>
    </div>
   </div>  

   <div className="y-about" >

    <div className="goal">
      <h1>Our Goal is to improve how you eat</h1>
      <p>Improving how you eat is important as assessing it</p>
    <div className="rectangle">
    </div>
    </div>

    <div>
      <Slider {...settings} >
          <Grid className="main3">
         <Grid.Row className="x-flex">
         <Grid.Column className="x-right" mobile={16} computer={8} tablet={8}  verticalAlign="middle" textAlign="center">
              <img  src="https://res.cloudinary.com/dzvhmiutm/image/upload/v1664107730/terrachow/Abt02_exc0os_tf8oag.png"  alt=""/>
            </Grid.Column>
           
          <Grid.Column mobile={16} computer={8} tablet={8} verticalAlign="top" className="x-left">
          <h3 className="bl">Enhancing <span className="red">Accessibity</span> For Consumers</h3>
          <p>We provide ease of access to your local and international resturants, physical and virtual vendors as wel. With just 3 ordering steps for your favorite meals.</p>
              <p className="cl">Freedom-as-a-Service</p>
              <p>We want you to be free as the air, choose items from as many restaurants as you wish, we will handle the rest.</p>
              <p className="cl">Better Quality of food</p>
              <p>We support restaurant partners  in nurturing a neutral platform by providing boxes and packaging materials to improve food quality and hygiene levels</p>
              </Grid.Column>
         </Grid.Row>
         </Grid>
          <Grid className="main3">
         <Grid.Row className="x-flex">
         <Grid.Column className="x-right" mobile={16} computer={8} tablet={8}  verticalAlign="middle" textAlign="center">
              <img  src="https://res.cloudinary.com/dzvhmiutm/image/upload/v1664107713/terrachow/Abt04_rrxsvl_eyvxbb.png"  alt=""/>
            </Grid.Column>
           
          <Grid.Column mobile={16} computer={8} tablet={8} verticalAlign="top" className="x-left">
          <p className="cl">Enhancing Accessibity For Consumers</p>
          <p>We provide ease of access to your local and international resturants, physical and virtual vendors as wel. With just 3 ordering steps for your favorite meals.</p>
              <h3 className="bl"><span className="red">Freedom</span>-as-a-Service</h3>
              <p>We want you to be free as the air, choose items from as many restaurants as you wish, we will handle the rest.</p>
              <p className="cl">Better Quality of food</p>
              <p>We support restaurant partners  in nurturing a neutral platform by providing boxes and packaging materials to improve food quality and hygiene levels</p>
              </Grid.Column>
         </Grid.Row>
         </Grid>
         <Grid className="main3">
         <Grid.Row className="x-flex">
         <Grid.Column className="x-right" mobile={16} computer={8} tablet={8}  verticalAlign="middle" textAlign="center">
              <img  src="https://res.cloudinary.com/dzvhmiutm/image/upload/v1650719577/terrachow/97546EED-CF20-4429-8F64-800B40EC2075.jpg"  alt=""/>
            </Grid.Column>
           
          <Grid.Column mobile={16} computer={8} tablet={8} verticalAlign="top" className="x-left">
          <p className="cl">Enhancing Accessibity For Consumers</p>
          <p>We provide ease of access to your local and international resturants, physical and virtual vendors as wel. With just 3 ordering steps for your favorite meals.</p>
              <p className="cl">Freedom-as-a-Service</p>
              <p>We want you to be free as the air, choose items from as many restaurants as you wish, we will handle the rest.</p>
              <h3 className="bl">Better <span className="red">Quality</span> of food</h3>
              <p>We support restaurant partners  in nurturing a neutral platform by providing boxes and packaging materials to improve food quality and hygiene levels</p>
              </Grid.Column>
         </Grid.Row>
         </Grid>
      </Slider>
    </div>
    <div className="who">
      <h1>Who are we</h1></div>
      <Grid className="main">
         <Grid.Row className="x-flex">
         <Grid.Column mobile={16} computer={8} tablet={8} verticalAlign="top" className="x-we">    
             
             <p>Terrachow is a Nigeria based B2B and B2C platform, a food ordering/delivery service, where users can order food and get it delivered to their location in minutes. We are using innovation and digital technology to revolutionize the food/restaurant industry and solve some of the biggest challenges affecting food system. We bring together restaurants (physical and virtual) together on a platform where food lovers can get easy accessibility to their vendor choice, we focus on removing dispatch-related headaches for vendors and eliminate queuing/waiting times suffered by customers. 
Terrachow also removes high charges labelled on customers/vendors by third party dispatch service. 
Food ordered & delivered through Terrachow is packed in a customized paper-bag "Terrabag" to preserve quality of food and keep food warm.</p>
          </Grid.Column>
          <Grid.Column className="x-right" mobile={16} computer={8} tablet={8}  verticalAlign="middle" textAlign="center">
              <img className="who-img" src="https://res.cloudinary.com/dzvhmiutm/image/upload/v1664106994/terrachow/Abt03_c114ut_mfpcbg.png"  alt=""/>
            </Grid.Column>
         </Grid.Row>
      </Grid>
         
    <div className="meet">
           <h1>Meet the Team</h1>
            <p>Get to know some of our members who leads our vision shaping the future of food delivery</p>
           <div className="rectangle1">
              </div>

<Grid className="main2" stackable>
    <Grid.Row columns={4}>
      <Grid.Column  id="column">
        <Image src="https://res.cloudinary.com/dzvhmiutm/image/upload/v1664107094/terrachow/1_gp3ocj_mafp7j.png"  className="a"  label={{
          color: 'black',
          content: <div> Jimoh Idris - UI/UX Designer</div>,
          ribbon: true,
        }}
        />
        <div className="overlay"><p>Jimoh Idris</p> <h5>UI/UX Designer</h5></div>
      </Grid.Column>
      <Grid.Column  id="column">
        <Image src="https://res.cloudinary.com/dzvhmiutm/image/upload/v1664107082/terrachow/2_em75xl_umulff.png"  className="b"  label={{
          color: 'black',
          content: <div>Ganiyat Abdulrahman - Data Analyst</div>,
          ribbon: true,
        }}
        />
        <div className="overlay"><p>Ganiyat Abdulrahman</p> <h5>Data Analyst</h5></div>
      </Grid.Column>
      <Grid.Column  id="column">
        <Image src="https://res.cloudinary.com/dzvhmiutm/image/upload/v1664107085/terrachow/3_apfovb_ffbd3i.png"  className="c" label={{
          color: 'black',
          content: <div>Oluwaseyi Daniel - Brand Identity Designer</div>,
          ribbon: true,
        }}/>
        <div className="overlay"><p>Oluwaseyi Daniel</p> <h5>Brand Identity Designer</h5></div>
      </Grid.Column>
      <Grid.Column  id="column">
        <Image src="https://res.cloudinary.com/dzvhmiutm/image/upload/v1664107069/terrachow/4_rirvvy_bzj7vu.png"  className="d" label={{
          color: 'black',
          content: <div>Audu Sheriff - Content Developer</div>,
          ribbon: true,
        }}
        />
        <div className="overlay"><p>Audu Sheriff</p> <h5>Content Developer</h5></div>
      </Grid.Column>
    </Grid.Row>

    <Grid.Row columns={4}>
      <Grid.Column id="column">
        <Image src="https://res.cloudinary.com/dzvhmiutm/image/upload/v1664107055/terrachow/5_qozc0g_fuqjr1.png"  className="e" 
        label={{
          color: 'black',
          content: <div>Abdulahmeed Abdulmalik- Social Media influencer </div>,
          ribbon: true,
        }}/>
        <div className="overlay"><p>Abdulahmeed Abdulmalik</p> <h5>Social Media influencer</h5></div>
      </Grid.Column>
      <Grid.Column id="column">
        <Image src="https://res.cloudinary.com/dzvhmiutm/image/upload/v1664107049/terrachow/6_wi1s2f_dcxt6o.png"  className="f"  label={{
          color: 'black',
          content: <div>Habeeb Abdulrahman- Software Engineer(CTO) </div>,
          ribbon: true,
        }}/>
        <div className="overlay"><p>Habeeb Abdulrahman</p> <h5>Software Developer</h5></div>
      </Grid.Column>
      <Grid.Column id="column">
        <Image src="https://res.cloudinary.com/dzvhmiutm/image/upload/v1664107037/terrachow/7_prfl7t_xtny3c.png"  className="g" 
        label={{
          color: 'black',
          content: <div>Sarah Oreoluwa- Social Media influencer </div>,
          ribbon: true,
        }}/>
        <div className="overlay"><p>Sarah Oreoluwa</p> <h5>Social Media Influencer</h5></div>
      </Grid.Column>
      <Grid.Column id="column">
        <Image src="https://res.cloudinary.com/dzvhmiutm/image/upload/v1664107044/terrachow/8_a5poro_j2jsd9.png"  className="h"
        label={{
          color: 'black',
          content: <div>Ejire Habeeb- Social Media influencer </div>,
          ribbon: true,
        }} />
        <div className="overlay"><p>Ejire Habeeb</p> <h5>Social Media Influencer</h5></div>
      </Grid.Column>
    </Grid.Row>

    <Grid.Row columns={4} className="w">
      <Grid.Column id="column">
        <Image src="https://res.cloudinary.com/dzvhmiutm/image/upload/v1664107011/terrachow/9_r0wxtc_mn0f5k.png"  className="i" 
        label={{
          color: 'black',
          content: <div>Zuliat Yinusa- Social Media influencer </div>,
          ribbon: true,
        }}/>
        <div className="overlay"><p>Zuliat Yinusa</p> <h5>Social Media Influencer</h5></div>
      </Grid.Column>
      <Grid.Column id="column">
        <Image src="https://res.cloudinary.com/dzvhmiutm/image/upload/v1664107017/terrachow/13_x2bdsx_aqosbq.jpg"  className="j" 
        label={{
          color: 'black',
          content: <div>Hafizah Muhyideen- FrontEnd Developer </div>,
          ribbon: true,
        }}/>
        <div className="overlay"><p>Hafizah Muhyideen</p> <h5>FrontEnd Developer</h5></div>
      </Grid.Column>
      <Grid.Column id="column">
        <Image src="./images/avatar.png"  className="k" 
        label={{
          color: 'black',
          content: <div>Ukpalichi David- FrontEnd Developer </div>,
          ribbon: true,
        }}/>
        <div className="overlay"><p>Ukpalichi David</p> <h5>FrontEnd Developer</h5></div>
      </Grid.Column>
      <Grid.Column id="column">
        <Image src="https://res.cloudinary.com/dzvhmiutm/image/upload/v1664107028/terrachow/11_awpnbe_ievnvw.png"  className="l"
        label={{
          color: 'black',
          content: <div>Issa Abdullahi- Social Media influencer </div>,
          ribbon: true,
        }} />
        <div className="overlay"><p>Issa Abdullahi</p> <h5>Brand Marketer</h5></div>
      </Grid.Column>
    </Grid.Row>
    </Grid>


        
    </div>
      
    </div>
    <Footer />
        </div>
    );
    }  
  
export default About

